.App-link {
  color: #61dafb;
}

#category_list {
  color: black;
}

.deleteBtn {
  float: right;
  cursor: pointer;
  color: red;
}

.bill_item {
  color: black;
}
