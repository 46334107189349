
$border-radius: 50%;
.more {
    float: right;
    cursor: pointer;
    padding-top: 5px;
    width: 32px;
    height: 32px;
    margin: auto;
    text-align: center;
}

.more:hover {
    border-radius: $border-radius;
    border: 1px solid white;
    background-color: grey;
}
