$primary-bg-color: #5daedd;
$primary-bg-image: url("./images/erol-ahmed-IHL-Jbawvvo-unsplash.jpg");
$secondary-bg-color: #202124;
$primary-color: black;
body {
  color: #666666;
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif, cursive, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Goldman", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  background-color: #ededed;
  color: $primary-color;
  // background-image: $primary-bg-image;
  background-repeat: repeat;
}

my-color {
  color: yellow;
}
