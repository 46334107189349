.header {
    width: 30%;
    z-index: 200;
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .header {
        width: 100%;
        z-index: 200;
    }
}
