.my-nav {
    //color: #5daedd;
    background-color: #17a2b8 !important;
}

.is-active {
    color: #17a2b8 !important;
}

.sticky {
    position: fixed;
    top: 0;
    z-index: 3000;
    margin-bottom: 60px;
    margin-top: 20px;
}
